import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { AbstractControl, FormArray } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { ProductIconComponent } from '@givve/ui-kit/components';
import { provideComponentStore } from '@ngrx/component-store';
import { TranslateModule } from '@ngx-translate/core';
import { of, switchMap, tap } from 'rxjs';
import { CsvPreviewTableEditFormComponent } from '../csv-preview-table-edit-form/csv-preview-table-edit-form.component';
import { CsvTemplateFormControl, CsvTemplateFormGroup } from '../models/csv-template';
import { PreviewDialogData } from '../models/preview-dialog-data';
import { CsvTemplateService } from '../services/csv-template.service';
import { CsvUploadPreviewTableComponent } from './csv-upload-preview-table/csv-upload-preview-table.component';
import { CsvUploadPreviewStore } from './store/csv-upload-preview.store';

@Component({
  selector: 'app-csv-upload-preview-dialog',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    CsvUploadPreviewTableComponent,
    MatExpansionModule,
    MatDialogModule,
    MatButtonModule,
    ProductIconComponent,
    MatTooltipModule,
    CsvPreviewTableEditFormComponent,
    FaIconComponent,
  ],
  templateUrl: './csv-upload-preview-dialog.component.html',
  providers: [provideComponentStore(CsvUploadPreviewStore)],
})
export class CsvUploadPreviewDialogComponent {
  dialogRef = inject(MatDialogRef<CsvUploadPreviewDialogComponent>);
  private dialogData = inject<PreviewDialogData>(MAT_DIALOG_DATA);

  csvUploadPreviewStore = inject(CsvUploadPreviewStore);
  csvTemplateService = inject(CsvTemplateService);

  newElement: CsvTemplateFormGroup | undefined;

  vm$ = of('').pipe(
    tap(this.csvUploadPreviewStore.setupData(this.dialogData)),
    switchMap(() => this.csvUploadPreviewStore.vm$)
  );

  formGroupUpdated(fg: { [key: string]: AbstractControl }) {
    this.csvUploadPreviewStore.updateRow(fg);
  }

  formGroupDeleted(fg: CsvTemplateFormGroup) {
    this.csvUploadPreviewStore.deleteRow(fg);
  }

  formGroupAdded(fg: { [key: string]: AbstractControl }) {
    this.newElement = undefined;
    this.csvUploadPreviewStore.addRow(fg);
  }

  submitFormArray(formArray: FormArray) {
    const csvData = formArray.getRawValue().map((row, index) => {
      delete row.index;
      const formGroup = formArray.at(index) as CsvTemplateFormGroup;
      Object.keys(row).forEach((key) => {
        const formElement = formGroup.controls[key];
        if (formElement instanceof CsvTemplateFormControl) {
          if (formElement.ignore) {
            delete row[key];
          }
          if (formElement.removeWhenEmpty && !row[key]) {
            delete row[key];
          }
        } else if (formElement instanceof CsvTemplateFormGroup) {
          const formGroupValues = formElement.getRawValue();

          const flattedValues = this.flattenObject(formGroupValues);

          if (formElement.removeWhenEmpty && !Object.values(flattedValues).some((value) => value)) {
            delete row[key];
          }
        }
      });
      return row;
    });

    this.dialogRef.close(csvData);
  }

  flattenObject(obj: Record<string, any>, parentKey = '', result: Record<string, any> = {}): Record<string, any> {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const newKey = parentKey ? `${parentKey}.${key}` : key;

        if (typeof obj[key] === 'object' && obj[key] !== null) {
          // Rekursion, wenn der Wert ein Objekt ist
          this.flattenObject(obj[key], newKey, result);
        } else {
          // Andernfalls den Wert ins Resultat übernehmen
          result[newKey] = obj[key];
        }
      }
    }
    return result;
  }

  createNewElement() {
    this.newElement = CsvTemplateFormGroup.from(this.dialogData.template.form);
  }
}
