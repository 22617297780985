import { AbstractControl, FormGroup, Validators } from '@angular/forms';
import { VoucherCategory } from '@app/features/csv-upload/csv-upload.models';
import { CsvTemplate, CsvTemplateFormControl, CsvTemplateFormGroup, CsvTemplateType } from '../models/csv-template';
import {
  checkDuplicate,
  checkDuplicateTokens,
  imageIdIsAssignedToCustomer,
  properEmbossLine1,
  replaceSpecialCharsEmbossline,
  replaceWhiteSpace,
  requiresRenewal,
} from './co-template-validators';
import { CsvTemplateBuilder } from './csv-template-builder';

const EMBOSS_LINE_1_PATTERN = /^(?!^[\d\s]+$)[äöüÄÖÜa-z0-9\-.ß ]{1,21}$/i;
const EMBOSS_LINE_2_PATTERN = /^(?!^[\d\s]+$)[äöüÄÖÜa-z0-9\-.& ]{1,21}$/i;
const NAME_PATTERN = /^[\p{Alpha}\s'.-]*$/u;

export class CoCsvTemplateBuilder extends CsvTemplateBuilder {
  category: VoucherCategory;

  constructor(type: CsvTemplateType, category: VoucherCategory) {
    super(type);
    this.category = category;
  }

  static fromTemplate(template: CsvTemplate) {
    return new this(template.type, template.category)
      .setDescription(template.description)
      .setLabel(template.label)
      .setExampleData(template.exampleData)
      .setAdditionalFields(template.additionalFields);
  }

  getForm(): CsvTemplateFormGroup {
    let form: CsvTemplateFormGroup;
    switch (this.type) {
      case 'NEW_CARD':
        form = this.newCard();
        break;
      case 'RECARDING':
        form = this.recarding();
        break;
      case 'BULK_RECARDING':
        form = this.bulkRecarding();
        break;
    }

    return form;
  }

  build(): CsvTemplate {
    let template = super.build();
    template.category = this.category;
    return template;
  }

  private newCard() {
    return new CsvTemplateFormGroup<{ [key: string]: AbstractControl<any, any> }>(
      {
        index: new CsvTemplateFormControl({}),
        emboss_line_1: new CsvTemplateFormControl({
          label: 'Prägezeile 1',
          validatorOrOpts: [Validators.required, Validators.maxLength(21), Validators.pattern(EMBOSS_LINE_1_PATTERN)],
          format: replaceSpecialCharsEmbossline,
        }),
        emboss_line_2: new CsvTemplateFormControl({
          label: 'Prägezeile 2',
          validatorOrOpts: [Validators.required, Validators.maxLength(21), Validators.pattern(EMBOSS_LINE_2_PATTERN)],
          format: replaceSpecialCharsEmbossline,
        }),
        image_id: new CsvTemplateFormControl({
          label: 'Image Id',
          validatorOrOpts: {
            validators: [Validators.required, imageIdIsAssignedToCustomer(this.validationData)],
          },
        }),
        voucher_owner: new FormGroup({
          first_name: new CsvTemplateFormControl({
            label: 'Vorname',
            validatorOrOpts: [Validators.required, Validators.maxLength(20), Validators.pattern(NAME_PATTERN)],
          }),
          last_name: new CsvTemplateFormControl({
            label: 'Nachname',
            validatorOrOpts: [Validators.required, Validators.maxLength(20), Validators.pattern(NAME_PATTERN)],
          }),
          identifier: new CsvTemplateFormControl({
            label: 'Personalnummer',
          }),
          email: new CsvTemplateFormControl({
            label: 'Email',
            validatorOrOpts: [Validators.email, checkDuplicate(this.validationData, 'emails', 'error')],
          }),
        }),
        operation: new CsvTemplateFormControl({ value: 'revouchering' }),
      },
      { validatorOrOpts: [properEmbossLine1()] }
    );
  }

  private recarding() {
    return new CsvTemplateFormGroup<{ [key: string]: AbstractControl<any, any> }>(
      {
        index: new CsvTemplateFormControl({}),
        token: new CsvTemplateFormControl({
          label: 'Token',
          validatorOrOpts: [Validators.required, checkDuplicateTokens(this.validationData)],
        }),
        emboss_line_1: new CsvTemplateFormControl({
          label: 'Prägezeile 1',
          validatorOrOpts: [Validators.maxLength(21), Validators.pattern(EMBOSS_LINE_1_PATTERN)],
          format: replaceSpecialCharsEmbossline,
        }),
        emboss_line_2: new CsvTemplateFormControl({
          label: 'Prägezeile 2',
          validatorOrOpts: [Validators.maxLength(21), Validators.pattern(EMBOSS_LINE_2_PATTERN)],
          format: replaceSpecialCharsEmbossline,
        }),
        image_id: new CsvTemplateFormControl({
          label: 'Image Id',
          validatorOrOpts: {
            validators: [imageIdIsAssignedToCustomer(this.validationData)],
          },
        }),
        voucher_owner: new FormGroup({
          first_name: new CsvTemplateFormControl({
            label: 'Vorname',
            validatorOrOpts: [Validators.maxLength(20), Validators.pattern(NAME_PATTERN)],
          }),
          last_name: new CsvTemplateFormControl({
            label: 'Nachname',
            validatorOrOpts: [Validators.maxLength(20), Validators.pattern(NAME_PATTERN)],
          }),
          identifier: new CsvTemplateFormControl({
            label: 'Personalnummer',
          }),
          email: new CsvTemplateFormControl({
            label: 'Email',
            validatorOrOpts: [Validators.email, checkDuplicate(this.validationData, 'emails', 'error')],
            format: replaceWhiteSpace,
          }),
        }),
        operation: new CsvTemplateFormControl({ value: 'recarding' }),
      },
      { validatorOrOpts: [properEmbossLine1()] }
    );
  }

  private bulkRecarding() {
    return new CsvTemplateFormGroup<{ [key: string]: AbstractControl<any, any> }>(
      {
        index: new CsvTemplateFormControl({}),
        KundenNr: new CsvTemplateFormControl({
          label: 'KundenNr',
          ignore: true,
        }),
        KundenName: new CsvTemplateFormControl({
          label: 'KundenName',
          ignore: true,
        }),
        VoucherID: new CsvTemplateFormControl({
          label: 'VoucherID',
          ignore: true,
        }),
        token: new CsvTemplateFormControl({
          label: 'Token',
          validatorOrOpts: [Validators.required, checkDuplicateTokens(this.validationData)],
        }),
        Konfiguration: new CsvTemplateFormControl({
          label: 'Konfiguration',
          ignore: true,
        }),
        emboss_line_1: new CsvTemplateFormControl({
          label: 'Prägezeile1',
          validatorOrOpts: [Validators.maxLength(21), Validators.pattern(EMBOSS_LINE_1_PATTERN)],
          format: replaceSpecialCharsEmbossline,
        }),
        emboss_line_2: new CsvTemplateFormControl({
          label: 'Prägezeile2',
          validatorOrOpts: [Validators.maxLength(21), Validators.pattern(EMBOSS_LINE_2_PATTERN)],
          format: replaceSpecialCharsEmbossline,
        }),
        voucher_owner: new CsvTemplateFormGroup({
          first_name: new CsvTemplateFormControl({
            label: 'Vorname',
            validatorOrOpts: [Validators.maxLength(20), Validators.pattern(NAME_PATTERN)],
          }),
          last_name: new CsvTemplateFormControl({
            label: 'Nachname',
            validatorOrOpts: [Validators.maxLength(20), Validators.pattern(NAME_PATTERN)],
          }),
          identifier: new CsvTemplateFormControl({
            label: 'Personalnummer',
          }),
        }),
        Ablaufdatum: new CsvTemplateFormControl({
          label: 'Ablaufdatum',
          ignore: true,
        }),
        Erneuern: new CsvTemplateFormControl({
          label: 'Erneuern?',
          ignore: true,
        }),
        Namensänderung: new CsvTemplateFormControl({
          label: 'Namensänderung?',
          ignore: true,
        }),
        operation: new CsvTemplateFormControl({ value: 'recarding' }),
      },
      { validatorOrOpts: [properEmbossLine1()], condition: requiresRenewal }
    );
  }
}
