<app-table-virtual-scroll-viewport [rowHeight]="'40'">
  <table
    [appVirtualDataSource]="datasource()"
    mat-table
    multiTemplateDataRows>
    <ng-container matColumnDef="index">
      <th
        *matHeaderCellDef
        mat-header-cell></th>
      <td
        *matCellDef="let element"
        mat-cell>
        {{ element.get('index').value }}
      </td>
    </ng-container>
    @for (column of displayedColumns(); track column; let i = $index) {
      @if (column !== 'index' && column !== 'editForm') {
        <ng-container [matColumnDef]="column">
          <th
            *matHeaderCellDef
            mat-header-cell>
            {{ 'csv-upload-preview-table.header.' + column | translate }}
          </th>
          <td
            *matCellDef="let element"
            [attr.data-cy]="column + '-' + element.get('index').value"
            [appBgError]="element.get(column).errors"
            [matTooltip]="element.get(column).errors | tooltipHint"
            mat-cell>
            <div>
              {{ element.get(column).value || '&nbsp;' }}
            </div>
            <div class="tw-text-red-500">
              @for (item of element.get(column).errors | keyvalue; track $index) {
                @if (!element.get(column).errors[$any(item).key].hint) {
                  <span [attr.data-cy]="column + '-' + element.get('index').value + '-' + item.key">
                    {{
                      'csv-upload-preview-table.errors.' + item.key
                        | translate: element.get(column).errors[$any(item).key]
                    }}
                  </span>
                } @else {
                  <!-- emtpy span for e2e tests that we can show that we have an hint -->
                  <span [attr.data-cy]="column + '-' + element.get('index').value + '-' + item.key"></span>
                }
              }
            </div>
          </td>
        </ng-container>
      }
    }

    <ng-container matColumnDef="expandForm">
      <th
        *matHeaderCellDef
        mat-header-cell
        aria-label="row actions">
        &nbsp;
      </th>
      <td
        *matCellDef="let element"
        mat-cell>
        <div class="tw-flex">
          <button
            class="sm_button tw-flex tw-items-center tw-justify-center"
            (click)="setExpanded(expandedElement() === element ? null : element); $event.stopPropagation()"
            aria-label="expand row"
            mat-icon-button
            data-cy="csv-preview-table-edit-button">
            <fa-icon
              class="tw-flex"
              [icon]="expandedElement() === element ? 'close' : 'pen'" />
          </button>

          <button
            class="sm_button tw-flex tw-items-center tw-justify-center"
            (click)="deleteElement(element)"
            aria-label="delete row"
            mat-icon-button
            data-cy="csv-preview-table-delete-button">
            <fa-icon
              class="tw-flex"
              [icon]="'trash'" />
          </button>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="editForm">
      <td
        class="tw-border-b-0"
        *matCellDef="let element"
        [attr.colspan]="displayedColumnsWithExpand().length"
        mat-cell>
        <div [@detailExpand]="element === expandedElement() ? 'expanded' : 'collapsed'">
          <app-csv-preview-table-edit-form
            [element]="expandedElement()"
            [columns]="displayedColumns()"
            (formGroupChanged)="expandedFormGroupChanged($event)"></app-csv-preview-table-edit-form>
        </div>
      </td>
    </ng-container>

    <tr
      *matHeaderRowDef="displayedColumnsWithExpand(); sticky: true"
      [style.height.px]="40"
      mat-header-row></tr>
    <tr
      class="tw-h-10"
      *matRowDef="let row; columns: displayedColumnsWithExpand()"
      mat-row></tr>

    <tr
      class="tw-h-0"
      *matRowDef="let row; columns: ['editForm']"
      mat-row></tr>
  </table>
</app-table-virtual-scroll-viewport>
