<!---->
@if (expandedFormGroup()) {
  <form
    class="tw-m-3 tw-flex tw-rounded-md tw-border tw-border-solid tw-border-slate-300 tw-p-3"
    [formGroup]="expandedFormGroup()"
    (ngSubmit)="submit()">
    <div class="tw-flex tw-flex-row tw-flex-wrap tw-gap-8">
      @for (control of controls(); track control) {
        <mat-form-field class="tw-flex-shrink-0 tw-flex-grow-0 tw-basis-[30%]">
          <mat-label>
            {{ control.control.label }}
          </mat-label>
          <input
            [id]="control.key"
            [formControl]="control.control"
            [attr.data-cy]="'csv-edit-form-input-' + control.key"
            matInput
            type="text" />
          @if (control.control.errors) {
            <mat-error>
              @for (item of control.control.errors | keyvalue; track $index) {
                @if ($index > 0) {
                  ,
                }

                {{ 'csv-upload-preview-table.errors.' + item.key | translate: control.control.errors[item.key] }}
              }
            </mat-error>
          }
        </mat-form-field>
      }
    </div>

    <div class="tw-flex-grow-0 tw-basis-0 tw-flex tw-flex-col tw-gap-2">
      <button
        class="tw-ml-auto tw-block"
        [disabled]="expandedFormGroup().invalid || expandedFormGroup().pristine"
        color="primary"
        mat-flat-button
        type="submit"
        data-cy="csv-edit-form-save-button">
        {{ 'common.submit' | translate }}
      </button>
      @if (showCancel()) {
        <button
          class="tw-ml-auto tw-block"
          (click)="cancelPressed.emit()"
          mat-flat-button
          type="button"
          data-cy="csv-edit-form-cancel-button">
          {{ 'common.cancel' | translate }}
        </button>
      }
    </div>
  </form>
}
