import { AbstractControl, FormControl, FormControlOptions, FormGroup, ValidatorFn } from '@angular/forms';
import { VoucherCategory } from '@app/features/csv-upload/csv-upload.models';

export interface CsvTemplate<T = any> {
  type: CsvTemplateType;
  category: VoucherCategory;
  label?: string;
  description?: string;
  exampleData?: T;
  form: CsvTemplateFormGroup<T>;
  headers?: string[];
  additionalFields?: { [key: string]: AbstractControl<any, any> };
}

export type CsvTemplateType = 'NEW_CARD' | 'RECARDING' | 'BULK_RECARDING' | 'LOAD_ORDER';

export const CSV_TEMPLATE_TYPE: { [key in CsvTemplateType]: CsvTemplateType } = {
  NEW_CARD: 'NEW_CARD',
  RECARDING: 'RECARDING',
  BULK_RECARDING: 'BULK_RECARDING',
  LOAD_ORDER: 'LOAD_ORDER',
};

export class CsvTemplateFormControl<T = any> extends FormControl {
  label?: string;
  format?: Function;
  removeWhenEmpty?: boolean;
  ignore = false;
  constructor(control: CsvTemplateFormControlData<T>) {
    super(control.value, control.validatorOrOpts);
    this.label = control.label;
    this.format = control.format;
    this.ignore = !!control.ignore;
    this.removeWhenEmpty = control.removeWhenEmpty;
  }

  static from(control: CsvTemplateFormControl): CsvTemplateFormControl {
    var format = control.format ? (control.format as (value: string) => string) : (val: any) => val;
    return new CsvTemplateFormControl({
      value: control.value,
      label: control.label,
      validatorOrOpts: control.validator,
      ignore: control.ignore,
      format: format,
      removeWhenEmpty: control.removeWhenEmpty,
    });
  }

  setValue(
    value: T,
    options?: {
      onlySelf?: boolean;
      emitEvent?: boolean;
      emitModelToViewChange?: boolean;
      emitViewToModelChange?: boolean;
    }
  ): void {
    if (this.format) {
      value = this.format(value);
    }
    super.setValue(value, options);
  }

  patchValue(
    value: T,
    options?: {
      onlySelf?: boolean;
      emitEvent?: boolean;
      emitModelToViewChange?: boolean;
      emitViewToModelChange?: boolean;
    }
  ): void {
    if (this.format) {
      value = this.format(value);
    }
    super.patchValue(value, options);
  }
}

export class CsvTemplateFormGroup<T = any> extends FormGroup {
  condition?: (value: T) => boolean;
  removeWhenEmpty?: boolean;

  constructor(
    controls: {
      [K in keyof T]: AbstractControl<any>;
    },
    opts?: {
      validatorOrOpts?: FormControlOptions | ValidatorFn | ValidatorFn[] | null | undefined;
      condition?: (value: T) => boolean;
      removeWhenEmpty?: boolean;
    }
  ) {
    super(controls, opts?.validatorOrOpts);
    this.condition = opts?.condition;
    this.removeWhenEmpty = opts?.removeWhenEmpty;
  }

  static from(fg: CsvTemplateFormGroup) {
    const newGroup: { [key: string]: AbstractControl } = {};

    Object.keys(fg.controls).forEach((key) => {
      const control = fg.get(key);
      if (control instanceof FormGroup) {
        newGroup[key] = CsvTemplateFormGroup.from(control);
      } else if (control instanceof CsvTemplateFormControl) {
        newGroup[key] = CsvTemplateFormControl.from(control);
      }
    });
    return new CsvTemplateFormGroup(newGroup, {
      validatorOrOpts: fg.validator,
      condition: fg.condition,
      removeWhenEmpty: fg.removeWhenEmpty,
    });
  }
}

interface CsvTemplateFormControlData<T>
  extends Partial<{
    label: string;
    value: T;
    validatorOrOpts: FormControlOptions | ValidatorFn | ValidatorFn[] | null | undefined;
    // this column will be not send to Backend when true
    ignore: boolean;
    removeWhenEmpty: boolean;
    format: (value: T) => T;
  }> {}
