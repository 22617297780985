import { animate, state, style, transition, trigger } from '@angular/animations';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { Component, computed, inject, input, output, signal, WritableSignal } from '@angular/core';
import { AbstractControl, FormArray, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FixedRowSizeTableVirtualScrollStrategyDirective } from '@app/shared/features/table-virtuell-scrolling/directives/fixed-row-size-table-virtual-scroll-strategy.directive';
import { VirtualTableDirective } from '@app/shared/features/table-virtuell-scrolling/directives/virtual-table.directive';
import { TableVirtualScrollViewportComponent } from '@app/shared/features/table-virtuell-scrolling/table-virtual-scroll-viewport.component';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { ConfirmDialogData, ConfirmDialogService } from '@givve/ui-kit/services';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CsvPreviewTableEditFormComponent } from '../../csv-preview-table-edit-form/csv-preview-table-edit-form.component';
import { CsvTemplateFormGroup } from '../../models/csv-template';
import { BgErrorDirective } from '../bg-error.directive';
import { CsvUploadPreviewStore } from '../store/csv-upload-preview.store';
import { TooltipHintPipe } from '../tooltip-error.pipe';

@Component({
  selector: 'app-csv-upload-preview-table',
  standalone: true,
  animations: [
    trigger('detailExpand', [
      state('collapsed,void', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
  imports: [
    CommonModule,
    MatTableModule,
    TranslateModule,
    MatTooltipModule,
    BgErrorDirective,
    TooltipHintPipe,
    ScrollingModule,
    TableVirtualScrollViewportComponent,
    VirtualTableDirective,
    FixedRowSizeTableVirtualScrollStrategyDirective,
    FormsModule,
    ReactiveFormsModule,
    CsvPreviewTableEditFormComponent,
    FaIconComponent,
    MatButtonModule,
  ],
  templateUrl: './csv-upload-preview-table.component.html',
  styleUrls: ['csv-upload-preview-table.component.scss'],
})
export class CsvUploadPreviewTableComponent {
  csvUploadPreviewStore = inject(CsvUploadPreviewStore);
  private confirmDialog = inject(ConfirmDialogService);
  private translate = inject(TranslateService);

  updatedFormGroup = output<{ [key: string]: AbstractControl }>();
  deletedFormGroup = output<CsvTemplateFormGroup>();

  datasource = input.required<CsvTemplateFormGroup[]>();
  templateHeaders = input.required<string[]>();
  formArray = input.required<FormArray>();
  example = input.required<boolean>();

  displayedColumns = computed(() => ['index', ...this.templateHeaders()]);
  displayedColumnsWithExpand = computed(() =>
    this.example() ? this.displayedColumns() : [...this.displayedColumns(), 'expandForm']
  );

  confirmDelete = {
    message: this.translate.instant('common.confirm_delete'),
    affirmButton: this.translate.instant('common.ok'),
    dismissButton: this.translate.instant('common.cancel'),
  } as ConfirmDialogData;

  expandedElement: WritableSignal<null | any> = signal(null);

  setExpanded(element: any | null) {
    this.expandedElement.set(element);
    this.csvUploadPreviewStore.setEditMode(element);
  }

  expandedFormGroupChanged(fg: { [key: string]: AbstractControl }) {
    this.updatedFormGroup.emit(fg);
    this.setExpanded(null);
  }

  deleteElement(element: CsvTemplateFormGroup) {
    this.confirmDialog.open(this.confirmDelete).affirm(() => this.deletedFormGroup.emit(element));
  }
}
