import { CommonModule } from '@angular/common';
import { Component, computed, input, output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TranslatePipe } from '@ngx-translate/core';
import { CsvTemplateFormControl, CsvTemplateFormGroup } from '../models/csv-template';

@Component({
  selector: 'app-csv-preview-table-edit-form',
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatFormFieldModule,
    TranslatePipe,
    MatButtonModule,
  ],
  templateUrl: './csv-preview-table-edit-form.component.html',
})
export class CsvPreviewTableEditFormComponent {
  element = input.required<null | CsvTemplateFormGroup>();
  columns = input.required<string[]>();

  showCancel = input<boolean>(false);
  cancelPressed = output<void>();

  formGroupChanged = output<{ [key: string]: AbstractControl }>();

  expandedFormGroup = computed(() => {
    if (this.element()) {
      var fb = new FormBuilder();

      return fb.group(
        this.columns().reduce(
          (acc, key) => {
            const control = CsvTemplateFormControl.from(this.element()!.get(key) as CsvTemplateFormControl);

            if (control) {
              key = key.replaceAll('.', '-');
              acc[key] = control;
            }

            return acc;
          },
          {} as { [key: string]: AbstractControl }
        ) //
      );
    }
    return null;
  });

  controls = computed(() => {
    let fg = this.expandedFormGroup();
    if (fg) {
      fg.markAllAsTouched();
      return Object.keys(fg.controls)
        .filter((key) => key != 'index')
        .map((key) => ({
          key,
          control: fg.get(key) as CsvTemplateFormControl,
        }));
    }
    return [];
  });

  private retransformKeys(fg: FormGroup): { [key: string]: AbstractControl } {
    const transformedControls: { [key: string]: AbstractControl } = {};

    Object.keys(fg.controls).forEach((key) => {
      const transformedKey = key.replaceAll('-', '.');
      transformedControls[transformedKey] = fg.get(key)!;
    });

    return transformedControls;
  }

  submit() {
    this.formGroupChanged.emit(this.retransformKeys(this.expandedFormGroup()!));
  }
}
