import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { URITemplate } from '@app/api/services/uri-template';
import { VoucherCategory } from '@app/features/csv-upload/csv-upload.models';
import { ApiResponse, CardOrder } from '@givve/ui-kit/models';
import { NotificationService } from '@givve/ui-kit/services';
import { Errors } from '@givve/ui-kit/utils';
import { TranslateService } from '@ngx-translate/core';
import { Observable, map, of, switchMap, tap } from 'rxjs';
import { CsvUploadPreviewDialogComponent } from '../../csv-upload-preview-dialog/csv-upload-preview-dialog.component';
import { PreviewDialogData } from '../../models/preview-dialog-data';

@Injectable({
  providedIn: 'root',
})
export class CoCsvUploadService {
  collectionUri = new URITemplate('{+api_v1_base}/card_orders');
  httpClient = inject(HttpClient);
  private matDialog = inject(MatDialog);
  private router = inject(Router);
  private notification = inject(NotificationService);
  private translate = inject(TranslateService);

  postCardOrder(data: {
    customer_id: string;
    entries_data: any[];
    submit_after_creation: boolean;
    voucher_category: VoucherCategory;
  }): Observable<CardOrder> {
    return this.httpClient
      .post<ApiResponse<CardOrder>>(this.collectionUri.build(), data)
      .pipe(map((response) => response.data));
  }

  openDialog(previewData: PreviewDialogData, customer_id?: string, category?: VoucherCategory) {
    return this.matDialog
      .open(CsvUploadPreviewDialogComponent, {
        width: '90%',
        data: previewData,
      })
      .afterClosed()
      .pipe(
        switchMap((formArray: any[]) => {
          if (formArray && customer_id && category) {
            return this.postCardOrder({
              entries_data: formArray,
              customer_id,
              voucher_category: category,
              submit_after_creation: true,
            }).pipe(
              tap({
                next: (order) => {
                  this.router.navigate(['/card-orders', order.id]);
                },
                error: (error: Error) => {
                  this.notification.open({
                    message: Errors.getApiErrorMessage(error) || this.translate.instant('common.errors.general'),
                  });
                },
              })
            );
          } else {
            return of(null);
          }
        })
      );
  }
}
