@if (vm$ | async; as vm) {
  <h2
    class="tw-flex tw-items-center tw-pt-4"
    mat-dialog-title
    data-cy="csv-upload-preview-table">
    @if (vm.template.category) {
      <givve-product-icon
        class="tw-pr-4"
        [product]="vm.template.category"
        [size]="24" />
    } @else {
      <fa-icon
        class="tw-pr-4"
        icon="upload"
        size="lg" />
    }

    {{ 'common.' + vm.template.type | translate }} -
    {{ vm.template.label ? (vm.template.label | translate) : ('common.' + vm.template.category | translate) }}
    @if (!vm.example) {
      <button
        class="tw-ml-auto"
        (click)="createNewElement()"
        mat-mini-fab
        color="primary">
        <fa-icon
          icon="add"
          size="lg" />
      </button>
    }
  </h2>
  <mat-dialog-content class="tw-pt-1">
    @if (newElement) {
      <app-csv-preview-table-edit-form
        [element]="newElement"
        [columns]="vm.template.headers"
        [showCancel]="true"
        (cancelPressed)="newElement = undefined"
        (formGroupChanged)="formGroupAdded($event)"></app-csv-preview-table-edit-form>
    }

    <mat-accordion multi>
      @if (vm.hintAndErrorDatasource.length) {
        <mat-expansion-panel [expanded]="vm.hintAndErrorDatasource.length">
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{ 'csv-upload.entries-with-errors-and-hints' | translate }}
            </mat-panel-title>
            <mat-panel-description>
              {{ vm.hintAndErrorDatasource.length }} {{ 'common.entries' | translate }}
            </mat-panel-description>
          </mat-expansion-panel-header>
          <app-csv-upload-preview-table
            [datasource]="vm.hintAndErrorDatasource"
            [templateHeaders]="vm.template.headers"
            [formArray]="vm.formArray"
            [example]="vm.example"
            (updatedFormGroup)="formGroupUpdated($event)"
            (deletedFormGroup)="formGroupDeleted($event)"></app-csv-upload-preview-table>
        </mat-expansion-panel>
      }

      @if (vm.noErrorsDataSource.length) {
        <mat-expansion-panel [expanded]="!vm.hintAndErrorDatasource.length">
          <mat-expansion-panel-header>
            <mat-panel-title> {{ 'csv-upload.entries-without-errors' | translate }}</mat-panel-title>
            <mat-panel-description>
              {{ vm.noErrorsDataSource.length }} {{ 'common.entries' | translate }}
            </mat-panel-description>
          </mat-expansion-panel-header>
          <app-csv-upload-preview-table
            [datasource]="vm.noErrorsDataSource"
            [templateHeaders]="vm.template.headers"
            [formArray]="vm.formArray"
            [example]="vm.example"
            (updatedFormGroup)="formGroupUpdated($event)"
            (deletedFormGroup)="formGroupDeleted($event)"></app-csv-upload-preview-table>
        </mat-expansion-panel>
      }
    </mat-accordion>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button
      mat-button
      mat-dialog-close>
      {{ 'common.cancel' | translate }}
    </button>
    @if (!vm.example) {
      <div
        [matTooltip]="'csv-upload-preview-table.save-disabled-editmode' | translate"
        [matTooltipDisabled]="!vm.editMode"
        matTooltipPosition="above">
        <button
          [disabled]="
            vm.hasErrorEntries || vm.editMode || vm.noErrorsDataSource.length + vm.hintAndErrorDatasource.length === 0
          "
          (click)="submitFormArray(vm.formArray)"
          color="primary"
          mat-flat-button
          data-cy="csv-upload-preview-table-submit"
          type="submit">
          {{
            'csv-upload-preview-table.submit-order'
              | translate: { value: vm.noErrorsDataSource.length + vm.hintAndErrorDatasource.length }
          }}
        </button>
      </div>
    }
  </mat-dialog-actions>
}
