import { AbstractControl, FormGroup } from '@angular/forms';
import { CsvTemplate, CsvTemplateFormControl, CsvTemplateFormGroup, CsvTemplateType } from '../models/csv-template';
import { ValidationData } from '../models/validation-data';

export abstract class CsvTemplateBuilder {
  type: CsvTemplateType;
  validationData: ValidationData = {} as ValidationData;
  label?: string;
  description?: string;
  exampleData?: any;
  additionalFields?: { [key: string]: AbstractControl<any, any> };

  constructor(type: CsvTemplateType) {
    this.type = type;
  }

  setLabel(label: string | undefined) {
    this.label = label;
    return this;
  }

  setDescription(description: string | undefined) {
    this.description = description;
    return this;
  }

  setExampleData(exampleData: any | undefined) {
    this.exampleData = exampleData;
    return this;
  }

  setAdditionalFields(additionalFields: { [key: string]: AbstractControl<any, any> } | undefined) {
    this.additionalFields = additionalFields;
    return this;
  }

  setValidationData(validationData: ValidationData) {
    this.validationData = validationData;
    return this;
  }

  private getTemplateHeaders(group: FormGroup, level: string = ''): string[] {
    return Object.keys(group.controls).flatMap((key) => {
      const control = group.get(key);
      const fullKey = level ? `${level}.${key}` : key;

      if (control instanceof CsvTemplateFormControl && control.label && !control.ignore) {
        return [fullKey];
      } else if (control instanceof FormGroup) {
        return this.getTemplateHeaders(control, fullKey);
      }
      return [];
    });
  }

  abstract getForm(): CsvTemplateFormGroup;

  build(): CsvTemplate {
    let form: CsvTemplateFormGroup = this.getForm();

    if (this.additionalFields) {
      Object.keys(this.additionalFields).forEach((key) => {
        form.addControl(key, this.additionalFields![key]!);
      });
    }

    return {
      type: this.type,
      label: this.label,
      exampleData: this.exampleData,
      description: this.description,
      form: form,
      headers: this.getTemplateHeaders(form),
      additionalFields: this.additionalFields,
    } as CsvTemplate;
  }
}
