import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { ValidationData } from '../models/validation-data';

export function properEmbossLine1(): ValidatorFn {
  return (form: AbstractControl): ValidationErrors | null => {
    const formValue = form.getRawValue();
    const voucher_owner = formValue.voucher_owner;
    const emboss_line_1 = formValue.emboss_line_1;

    if (!emboss_line_1) {
      return null;
    }

    const embossLine1 = emboss_line_1.toLowerCase();
    const vo_first_name = voucher_owner?.first_name?.toLowerCase();
    const vo_last_name = voucher_owner?.last_name?.toLowerCase();

    if (embossLine1 !== vo_first_name + ' ' + vo_last_name) {
      const currentErrors = form.get('emboss_line_1')?.errors;
      form.get('emboss_line_1')?.setErrors({ ...currentErrors, properEmbossLine1: { hint: true } });
      return null;
    } else {
      const currentErrors = form.get('emboss_line_1')?.errors;
      delete currentErrors?.properEmbossLine1;
      form
        .get('emboss_line_1')
        ?.setErrors(currentErrors && Object.keys(currentErrors).length ? { ...currentErrors } : null);
    }

    return null;
  };
}

export function checkDuplicateTokens(validationData?: ValidationData): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const tokens = validationData?.tokens ?? [];
    const matchedTokens = tokens.filter((token: string) => token === control.value);

    return !control.value || matchedTokens.length <= 1 ? null : { duplicate: true };
  };
}

export function imageIdIsAssignedToCustomer(validationData?: ValidationData): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    return !control.value || validationData?.possibleImageIds?.includes(control.value)
      ? null
      : { imageIdIsAssignedToCustomer: true };
  };
}

export function onlyActiveCustomer(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    return !control.value || (control.value.active && control.value.kyb_state === 'accepted')
      ? null
      : { invalid_customer: true };
  };
}

export function checkDuplicate(
  validationData: ValidationData,
  key: string,
  hintOrError: 'hint' | 'error'
): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) {
      return null;
    }

    const vd = validationData as any;

    if (vd?.[key]?.filter((item: string) => item === control.value).length > 1) {
      return { duplicate: hintOrError === 'hint' ? { hint: true } : true };
    }

    return null;
  };
}

export function replaceSpecialCharsEmbossline(value: string): string {
  return value.replace(/ß/g, 'ss');
}

export function replaceWhiteSpace(value: any): string {
  return value.toString().replace(/\s/g, '');
}

export const requiresRenewal = (value: any) => {
  const possibleTrueValues = ['ja', 'yes', 'j', 'y'];
  if (possibleTrueValues.includes(value['Erneuern?']?.toLowerCase())) {
    return true;
  }

  return false;
};
