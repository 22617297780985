import { Injectable, inject } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { Params } from '@angular/router';
import saveAs from 'file-saver';
import { Papa } from 'ngx-papaparse';
import { CsvTemplate, CsvTemplateFormControl, CsvTemplateFormGroup } from '../models/csv-template';

@Injectable({
  providedIn: 'root',
})
export class CsvTemplateService {
  private papa = inject(Papa);
  private fb = inject(FormBuilder);

  validateCsvStructure(form: FormGroup, data: any[]): boolean {
    const neededLabels = Object.keys(this.extractKeysAndLabelsFromGroup(form));
    const currentLabels = Object.keys(data[0]);

    return (
      neededLabels.length === currentLabels.length &&
      neededLabels.every((label: string) => currentLabels.includes(label))
    );
  }

  /**
   * Creates a FormGroup based on the provided CsvTemplate and data.
   * @param template The CsvTemplate to use for creating the form group.
   * @param data The data to populate the form group with.
   * @returns A FormGroup populated with the provided data.
   */
  createFormGroup(template: CsvTemplate, data: any[]): FormGroup<{ csvData: FormArray }> {
    const csvFormGroup = this.fb.group({
      csvData: this.fb.array([]),
    });
    const keysAndLabelsDeep = this.extractKeysAndLabelsDeep(template);
    const formArray = csvFormGroup.controls.csvData as FormArray;

    data.forEach((element, index) => {
      const row = CsvTemplateFormGroup.from(template.form);
      if (!row.condition || row.condition(element)) {
        row.patchValue({ index: index + 1 });
        Object.keys(element).forEach((key) => {
          row.get(keysAndLabelsDeep[key])!.patchValue(this.cleanupValue(element[key]));
        });
        formArray.push(row);
      }
    });

    return csvFormGroup;
  }

  /**
   * Extracts keys and labels from the provided CsvTemplate, including nested groups.
   * @param template The CsvTemplate to extract keys and labels from.
   * @returns An object containing the keys and labels with dot notation:
   * Example: {
      "Prägezeile 1": "emboss_line_1",
      "Prägezeile 2": "emboss_line_2",
      "Vorname": "voucher_owner.first_name",
      "Nachname": "voucher_owner.last_name",
      "Email": "voucher_owner.email",
      "Regionale Einschränkung PLZ": "contraints.lunch.regional"
     }
   */
  extractKeysAndLabelsDeep(template: CsvTemplate): Params {
    return this.extractKeysAndLabelsFromGroup(template.form, true, '');
  }

  extractKeysAndLabelsDeepWithoutIgnored(template: CsvTemplate): Params {
    return this.extractKeysAndLabelsWithoutIgnoredFormGroup(template.form, true, '');
  }

  /**
   * Recursively extracts keys and labels from a FormGroup.
   * @param group The FormGroup to extract keys and labels from.
   * @param deep Whether to include the nested structure with dot notation.
   * @param level The current level of nesting (needed for recursion).
   * @returns An object containing the keys and labels.
   */
  private extractKeysAndLabelsFromGroup(group: FormGroup, deep: boolean = false, level: string = ''): Params {
    let params: Params = {};
    Object.keys(group.controls).forEach((key) => {
      const control = group.get(key);
      if (control instanceof CsvTemplateFormControl && control.label) {
        params[control.label] = level.length ? level + '.' + key : key;
      } else if (control instanceof FormGroup) {
        if (deep) {
          level = level.length > 0 ? level + '.' + key : key;
        }
        params = {
          ...params,
          ...this.extractKeysAndLabelsFromGroup(control, deep, level),
        };
        level = '';
      }
    });

    return params;
  }

  /**
   * Recursively extracts keys and labels from a FormGroup.
   * @param group The FormGroup to extract keys and labels from.
   * @param deep Whether to include the nested structure with dot notation.
   * @param level The current level of nesting (needed for recursion).
   * @returns An object containing the keys and labels.
   */
  private extractKeysAndLabelsWithoutIgnoredFormGroup(
    group: FormGroup,
    deep: boolean = false,
    level: string = ''
  ): Params {
    let params: Params = {};
    Object.keys(group.controls).forEach((key) => {
      const control = group.get(key);
      if (control instanceof CsvTemplateFormControl && control.label && !control.ignore) {
        params[control.label] = level.length ? level + '.' + key : key;
      } else if (control instanceof FormGroup) {
        if (deep) {
          level = level.length > 0 ? level + '.' + key : key;
        }
        params = {
          ...params,
          ...this.extractKeysAndLabelsFromGroup(control, deep, level),
        };
        level = '';
      }
    });

    return params;
  }

  /**
   * Iterates over a nested FormGroup with multiple FormGroups inside and returns errors from the controls.
   * @param formGroup The FormGroup to iterate over.
   * @returns An object containing the errors from the controls.
   */
  getFormGroupErrors(formGroup: FormGroup): { [key: string]: any } {
    let errors: { [key: string]: any } = {};

    Object.keys(formGroup.controls).forEach((key) => {
      const control = formGroup.get(key);

      if (control instanceof FormGroup) {
        const nestedErrors = this.getFormGroupErrors(control);
        if (Object.keys(nestedErrors).length > 0) {
          errors[key] = nestedErrors;
        }
      } else if (control && control.errors) {
        errors[key] = control.errors;
      }
    });

    return errors;
  }

  cleanupValue(input: string): string {
    // \s+ stands for one or more whitespace characters
    // Replacing whitespace characters (e.g., tab, multiple spaces) with a single space
    return input.replace(/[\s]+/g, ' ').trim();
  }

  // Templategeneration start
  /**
   * Creates a CSV template based on the provided CsvTemplate.
   * @param template The CsvTemplate to use for creating the CSV.
   */
  createCsvTemplate(template: CsvTemplate, prefix: string) {
    const array = Object.keys(this.extractLabels(template));
    const csv = this.papa.unparse([array], { delimiter: ';' });
    const name = template.label
      ? prefix + '_' + template.label.split('.').at(-1)!.toUpperCase() + '_' + template.type
      : prefix + '_' + template.category.toUpperCase() + '_' + template.type;
    this.downloadFile(csv, name);
  }

  /**
   * Extracts labels from the provided CsvTemplate.
   * @param template The CsvTemplate to extract labels from.
   * @returns An object containing the labels:
   * Example: {
      "Prägezeile 1": "emboss_line_1",
      "Prägezeile 2": "emboss_line_2",
      "Image Id": "image_id",
      "Vorname": "first_name",
      "Nachname": "last_name",
      "Email": "email",
      "Regionale Einschränkung PLZ": "regional"
     }
   */
  private extractLabels(template: CsvTemplate): Params {
    return this.extractKeysAndLabelsFromGroup(template.form);
  }

  /**
   * Downloads a CSV file with the provided content and filename.
   * @param csv The CSV content to download.
   * @param filename The name of the file to download.
   */
  private downloadFile(csv: string, filename: string) {
    var blob = new Blob(['\uFEFF' + csv], { type: 'text/csv;charset=utf-8;' });
    saveAs(blob, filename + '.csv');
  }

  /**
   * Extracts templateHeaders from a CsvTemplate
   *
   * @param csvTemplate  - the csv template
   * @returns array of templateHeaders
   */
  extractTemplateHeaders(csvTemplate: CsvTemplate) {
    const keysAndLabelsDeep = this.extractKeysAndLabelsDeepWithoutIgnored(csvTemplate);
    const templateHeaders: string[] = [];
    Object.keys(keysAndLabelsDeep).forEach((key) => {
      templateHeaders.push(keysAndLabelsDeep[key]);
    });
    return templateHeaders;
  }

  // Templategeneration end
}
