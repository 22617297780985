import { inject, Injectable } from '@angular/core';
import { AbstractControl, FormArray, FormGroup } from '@angular/forms';
import { StatusType } from '@givve/ui-kit/models';
import { ComponentStore } from '@ngrx/component-store';
import { CsvTemplate, CsvTemplateFormGroup } from '../../models/csv-template';
import { PreviewDialogData } from '../../models/preview-dialog-data';
import { CsvTemplateService } from '../../services/csv-template.service';

export interface CsvUploadPreviewState {
  statusType: StatusType;
  formArray?: FormArray;
  template?: CsvTemplate;
  noErrorsDataSource: CsvTemplateFormGroup[];
  hintAndErrorDatasource: CsvTemplateFormGroup[];
  hasErrorEntries: boolean;
  example: boolean;
  editMode: boolean;
}

export const DEFAULT_STATE: CsvUploadPreviewState = {
  statusType: 'INIT',
  noErrorsDataSource: [],
  hintAndErrorDatasource: [],
  hasErrorEntries: false,
  example: true,
  editMode: false,
};

@Injectable()
export class CsvUploadPreviewStore extends ComponentStore<CsvUploadPreviewState> {
  private csvTemplateService = inject(CsvTemplateService);

  readonly vm$ = this.select((state) => state);

  constructor() {
    super(DEFAULT_STATE);
  }

  readonly setupData = this.updater((state, data: PreviewDialogData) => {
    if (!data.csvFormGroup) {
      return { ...state };
    }
    var formArray = data.csvFormGroup!.controls.csvData;

    return {
      ...state,
      ...this.getStateByFormArray(formArray),
      example: data.example,
      template: data.template,
      statusType: 'DATA',
    };
  });

  readonly updateRow = this.updater((state, updatedControls: { [key: string]: AbstractControl }) => {
    const index: number = updatedControls['index']?.value;

    const matchingGroup = state.formArray?.controls.find(
      (group) => (group as FormGroup).get('index')?.value === index
    ) as FormGroup | undefined;
    if (matchingGroup) {
      Object.keys(updatedControls).forEach((key) => {
        matchingGroup.get(key)?.setValue(updatedControls[key]?.value);
      });
    }

    return {
      ...state,
      ...this.getStateByFormArray(state.formArray!),
    };
  });

  readonly deleteRow = this.updater((state, updatedRow: CsvTemplateFormGroup) => {
    const index: number = updatedRow.get('index')!.value;

    const matchingGroupIdx =
      state.formArray?.controls.findIndex((group) => (group as FormGroup).get('index')?.value === index) ?? -1;

    if (matchingGroupIdx > -1) {
      state.formArray!.removeAt(matchingGroupIdx);
    }

    return {
      ...state,
      ...this.getStateByFormArray(state.formArray!),
    };
  });

  readonly addRow = this.updater((state, updatedControls: { [key: string]: AbstractControl }) => {
    let maxIndex = state.formArray?.controls
      .map((control) => (control as FormGroup).get('index')?.value ?? -Infinity)
      .reduce((max, current) => Math.max(max, current), -Infinity);

    let newRow = CsvTemplateFormGroup.from(state.template!.form);
    Object.keys(updatedControls).forEach((key) => {
      if (updatedControls[key]?.value) {
        newRow.get(key)?.setValue(updatedControls[key]?.value);
      }
    });

    newRow.patchValue({ index: maxIndex + 1 });

    state.formArray!.push(newRow);

    return {
      ...state,
      ...this.getStateByFormArray(state.formArray!),
    };
  });

  private getStateByFormArray(formArray: FormArray<FormGroup<any>>) {
    var formGroups = formArray.controls;

    var noErrorsDataSource = formGroups.filter((group) => group.valid).map((group) => group);
    var hintAndErrorDatasource = formGroups.filter((group) => group.invalid).map((group) => group);

    var hasErrorEntries = formGroups.some((group) => {
      const errorObj = this.csvTemplateService.getFormGroupErrors(group);
      return this.checkErrorsForRealErrors(errorObj);
    });

    return {
      formArray: formArray,
      noErrorsDataSource,
      hintAndErrorDatasource,
      hasErrorEntries,
    };
  }

  readonly setEditMode = this.updater((state, edit: boolean) => {
    return {
      ...state,
      editMode: edit,
    };
  });

  private checkErrorsForRealErrors(errors: { [key: string]: any }): boolean {
    let value = false;
    for (const key in errors) {
      if (typeof errors[key] === 'object') {
        value = this.checkErrorsForRealErrors(errors[key]);
      } else if (key !== 'hint') {
        value = true;
        break;
      }
    }
    return value;
  }
}
